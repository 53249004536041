export default () => {


	const tabs = document.querySelector('[data-tabs]');
	if (!tabs) {
		return;
	}
	const btns = tabs.querySelectorAll('[data-tab-btn]');
	btns.forEach(btn => {
		btn.addEventListener('click', function(e) {
			if(btn.classList.contains('active')) {
				return;
			} else if (btn.parentElement.querySelector('.active')) {
				const btnRemove = btn.parentElement.querySelector('.active')
				btnRemove.classList.remove('active');
				btn.classList.add('active');
				const dataBtn = btn.dataset.tabBtn;
				console.log(dataBtn);
				const array = tabs.querySelectorAll('[data-tab-box]');
				for (let i = 0; i < array.length; i++) {
					const element = array[i];
					// console.log(array[i].dataset.tabBox = dataBtn);
					if (element.dataset.tabBox === dataBtn) {
						element.classList.add('active')
					} else if (element.classList.contains('active')) {
						element.classList.remove('active');
					}
				}
			} else {
				btn.classList.add('active');
			}
		})
	});
}
