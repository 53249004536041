// import Swiper, {Autoplay} from 'swiper';
import Swiper from 'swiper/bundle';

export default () => {

	const $sliderCircle = document.querySelectorAll('[data-slider-circle]');

	if(!$sliderCircle) {
		return;
	}

	$sliderCircle.forEach((sliderEl) => {
		const sliderMy = new Swiper(sliderEl, {
			speed: 700,
			loop: true,
			// spaceBetween: 0,
			// slidesPerView: 1,
			slidesPerView: 'auto',
			// centeredSlides: true,
			// centeredSlidesBounds: true,
			// touchStartPreventDefault: false, // custom cursor
			observer: true,
			observeSlideChildren: true,
			observeParents: true,
			a11y: true,
      keyboardControl: true,
      grabCursor: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			// breakpoints: {
			// 	1200: {
			// 		spaceBetween: 0,
			// 	},
			// 	768: {
			// 		spaceBetween: 0,
			// 	},
			// 	0: {
			// 		spaceBetween: 0,
			// 	}
			// },
		});
		// sliderMy.on('slideChange', function () {
		// 	// console.log('slide changed');
		// 	// $sliderCircle2.classList.add('sssssssssss');
		// 	// if (!sliderEl.classList.contains('sssssssssss')) {
		// 	// 	sliderEl.classList.add('sssssssssss');
		// 	// 	setTimeout(() => {
		// 	// 		sliderEl.classList.remove('sssssssssss');
		// 	// 	}, 700);
		// 	// }
		// });
	});


};
