export default () => {


	const filterOpen = document.querySelector('[data-filter-open]');
	if (!filterOpen) {
		return;
	}
	filterOpen.addEventListener('click', function(e) {
		const body = document.body;
		body.classList.toggle('filter-open-body');
	})

	const filterClose = document.querySelectorAll('[data-close-filter]');
	filterClose.forEach(element => {
		element.addEventListener('click', function(e) {
			const body = document.body;
			body.classList.toggle('filter-open-body');
		})
	});
}
