import setFullHeight from './helpers/setFullHeight';
import header from './components/header.js';
import filter from './components/filter.js';
import slider from './components/slider.js';
import tabs from './components/tabs.js';
import animation from './components/animation.js';

export default () => {
	setFullHeight(); //Set VH variable for mobile safari 100VH, use scss mixin fullheight()
	header();
	filter();
	slider();
	tabs();
	animation();
};
