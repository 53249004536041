export default () => {

	const burger = document.querySelector('.burger-menu');
	if (!burger) {
		return;
	}
	burger.addEventListener('click', function(e) {
		const body = document.body;
		body.classList.toggle('menu-open');
	})

	let header = document.getElementById('header');
	let oldScrollTopPosition = 1;
	let scrollTopPosition = 1;

	window.onscroll = () => {
		scrollTopPosition = document.documentElement.scrollTop;
		if (scrollTopPosition < oldScrollTopPosition) {
			header.classList.add('header--fixed')
			header.classList.remove('header--hide')

		} else if (scrollTopPosition > oldScrollTopPosition) {
			header.classList.remove('header--fixed')
			header.classList.add('header--hide')
		}
		oldScrollTopPosition = scrollTopPosition;
		if (scrollTopPosition === 0) {
			header.classList.remove('header--fixed')
			header.classList.remove('header--hide')
		}
	}


};
