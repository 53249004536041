

import SplitType from 'split-type'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Parallax from 'parallax-scroll';


export default () => {

	const openTxt = document.querySelectorAll('[data-open-txt')
	openTxt.forEach(element => {
		element.addEventListener('click', function(e) {
			element.parentElement.classList.toggle('open-txt_open')
		})
	});


	const p = new Parallax('.js-parallax', {
		speed: 0.4, // Anything over 0.5 looks silly
	});

	p.animate();

	gsap.registerPlugin(ScrollTrigger);

	const designEl1 = document.querySelectorAll('.split')
	designEl1.forEach(el1 => {

		el1.classList.add('splitting')

		var lines = new SplitType(el1, {
			tagName: 'div',
		});

		lines.split('lines');

		const line = el1.querySelectorAll('.char')

		gsap
		.timeline({
				scrollTrigger: {
						trigger: el1,
						start: "top bottom-=100px",
				},
		})

		.fromTo(line, {
			yPercent: 100,
		}, {
			yPercent: 0,
			duration: 1.5,
			ease: "power4",
			stagger: 0.004
		})

	});

	const designEl2 = document.querySelectorAll('.split-hero')
	designEl2.forEach(el1 => {

		el1.classList.add('splitting')

		var lines = new SplitType(el1, {
			tagName: 'div',
		});

		lines.split('lines');

		const line = el1.querySelectorAll('.char')

		gsap
		.timeline({
				scrollTrigger: {
						trigger: el1,
						start: "top bottom-=100px",
				},
		})

		.fromTo(line, {
			yPercent: 100,
		}, {
			yPercent: 0,
			duration: 1.5,
			delay: 1,
			ease: "power4",
			stagger: 0.004
		})

	});


}
